import * as React from 'react';
import type { CategoriesViewProps } from '../../types';
import Container from '@mui/material/Container';
import { CollectionHoc } from './CollectionHoc';
import dynamic from 'next/dynamic';
import { FeatureGridA } from '../../components/CollectionCommon/FeatureGridA';
import { AdvertisementSlot } from '../../components/AdvertisementSlot';
import { PaperColorElevation } from '../../components/PaperColorElevation';
import styled from '@emotion/styled';
import { useAppContext } from '../../contexts/AppContext';
import { useMediaQuery } from '@mui/material';
import theme from '../../app/theme';
import { LayoutTypeEnum } from '../../types/enum';

const SliderList = dynamic<any>(
  () => import('../../components/CollectionCommon/CarouselA').then((mod) => mod.SliderList),
  {
    ssr: false,
  }
);

const SliderListB = dynamic<any>(
  () => import('../../components/CollectionCommon/CarouselB').then((mod) => mod.SliderListB),
  {
    ssr: false,
  }
);

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  height: 'auto',
}));

const AdvertisementSlotStyled = styled(AdvertisementSlot)(({ theme }) => ({
  boxShadow: theme.shadows[0],
  width: 'fit-content',
  margin: 'auto',
  background: 'grey',
}));

const AdvertisementSlotContainer = styled(PaperColorElevation)(({ theme }) => ({
  padding: 0,
  marginBottom: theme.spacing(5),
  marginTop: theme.spacing(5),
  boxShadow: theme.shadows[0],
  backgroundColor: 'grey',
}));

export const CollectionWrapper: React.FC<CategoriesViewProps> = (props: CategoriesViewProps) => {
  const { collections } = props;
  const appContext = useAppContext();
  const { componentOptions, pageOptions } = appContext.useConfig();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const homePageLayout = pageOptions.find((page) => page.name === 'index')?.layouts?.[0]?.imports?.[0]?.component;
  const layout =
    homePageLayout === 'ConservativeHome'
      ? LayoutTypeEnum.CONSERVATIVE
      : homePageLayout === 'ModerateHome'
      ? LayoutTypeEnum.MODERATE
      : homePageLayout === 'AggressiveHome'
      ? LayoutTypeEnum.AGGRESSIVE
      : LayoutTypeEnum.DEFAULT;

  if (layout === LayoutTypeEnum.CONSERVATIVE || layout === LayoutTypeEnum.DEFAULT) {
    // Return without any advertisements
    return (
      <Container style={{ marginTop: '40px' }}>
        {collections.map((collectionItem, index) => {
          switch (collectionItem.component?.name) {
            case 'CarouselA':
              return <SliderList key={index} template={collectionItem.component.name} {...collectionItem} />;
            case 'CarouselB':
              return <SliderListB key={index} template={collectionItem.component.name} {...collectionItem} />;
            case 'FeatureGridA':
              return <FeatureGridA template={collectionItem?.component?.name} items={collectionItem.games} />;
            default:
              return <SliderList key={index} template={'CarouselA'} {...collectionItem} />;
          }
        })}
      </Container>
    );
  }

  const slotIdentifier =
    layout == LayoutTypeEnum.AGGRESSIVE
      ? componentOptions.aggressive_home_middle_id!
      : componentOptions.moderate_home_middle_id!;

  const variant = md ? '728x90' : !md && layout == LayoutTypeEnum.AGGRESSIVE ? '300x250' : '320x100';

  return (
    <Container style={{ marginTop: '40px' }}>
      {collections.map((collectionItem, index) => {
        const showAd =
          (layout === LayoutTypeEnum.AGGRESSIVE && index % 1 === 0) ||
          (layout === LayoutTypeEnum.MODERATE && index % 2 === 1);

        return (
          <React.Fragment key={index}>
            {collectionItem.component?.name === 'CarouselA' && (
              <SliderList template={collectionItem.component.name} {...collectionItem} />
            )}
            {collectionItem.component?.name === 'CarouselB' && (
              <SliderListB template={collectionItem.component.name} {...collectionItem} />
            )}
            {collectionItem.component?.name === 'FeatureGridA' && (
              <FeatureGridA template={collectionItem?.component?.name} items={collectionItem.games} />
            )}
            {!collectionItem.component && <SliderList template={'CarouselA'} {...collectionItem} />}
            {showAd && (
              <AdvertisementSlotContainer>
                <AdvertisementSlotStyled slotIdentifier={slotIdentifier} variant={variant} />
              </AdvertisementSlotContainer>
            )}
          </React.Fragment>
        );
      })}
    </Container>
  );
};

const Collection = CollectionHoc(CollectionWrapper);

export { Collection };
